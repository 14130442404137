
<template>
  <div class="lower-sec">
    <div class="sec5 py-12">
      <div class="container flex justify-center">
        <div class="w-6/12">
          <h1>NOW WHAT?</h1>
          <div>
            <p>
              We know we just threw a lot of information at you. It’s a lot to digest, but you don’t have to be in this alone. Hopefully, these stats helped you see new perspectives as you consider
              digital transformation or
              mobile app developmen. Understanding your audience’s spending habits, behaviors, and much more is crucial when it comes to building your brand for mobile.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container flex justify-center my-12">
      <p class="w-6/12">
        Have an idea for a mobile app? Have an app but want to make some improvements? Not sure where to start? We’re here to
        chat and ready to digitally transform your business.
      </p>
    </div>
  </div>
</template>
<script>
import "./style.scss";
export default {
  created() {
    document.querySelector("head title").innerText = "Blogs | Gap Dynamics";
  },
};
</script>
