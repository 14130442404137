<template>
  <div class="sec2 container flex justify-center">
    <div class="w-6/12">
      <p class="mt-8">
        Do you ever wonder just how much revenue mobile apps generate in a year?
        Or how many hours users spend on their phones per day? Or maybe you’re
        on the fence of whether to make your brand accessible on mobile.
        Regardless, there’s a slew of statistics to answer all these questions
        and more.
      </p>
      <p>
        At Gap Dynamics, we love all things tech. From mobile apps to web
        development to user behaviors, we are intrigued by it all. We’ve done
        our research and gathered these top mobile stats to know in 2020.
      </p>
    </div>
  </div>
</template>
<script>
import "./style.scss";
export default {
  created() {
    document.querySelector("head title").innerText = "Blogs | Gap Dynamics";
  },
};
</script>
