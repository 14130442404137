<template>
  <div class="carousel-silk">
    <carousel :items-to-show="4">
      <slide v-for="slide in 10" :key="slide">
        <div class="card-slide bg-white">
          <div :style="card_bg"></div>
          <div class="text-left m-4">
            <h1>App of the week</h1>
            <h2>I’m Over the Moon for This Simple Design App</h2>
            <p>published by</p>
            <p>on 27th, may, 2021</p>
          </div>
        </div>
      </slide>
      <template #addons>
        <navigation />
        <!-- <pagination /> -->
      </template>
    </carousel>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import "./style.scss";
import {
  Carousel,
  Slide,
  // , Pagination,
  Navigation,
} from "vue3-carousel";

export default {
  components: {
    Carousel,
    Slide,
    // Pagination,
    Navigation,
  },
  data() {
    return {
      card_bg: {
        backgroundImage: `url(${require("@/assets/card.jpg")})`,
        backgrounRepeat: `no-repeat`,
        backgroundSize: `cover`,
        height: `220px`,
      },
    };
  },
};
</script>
