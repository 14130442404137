
<template>
  <div class="sec4 py-12" :style="{ 'background': bgcolor }">
    <div class="container flex justify-center">
      <div class="w-6/12">
        <h1>MOBILE APP USER STATS</h1>
        <!-- sec1 -->
        <div>
          <h2>1. MOBILE APPS ARE PROJECTED TO GENERATE MORE THAN $935 BILLION USD IN REVENUE VIA PAID DOWNLOADS AND IN-APP ADVERTISING BY 2023. (STATISTA)</h2>
          <p>Having a paid app sounds like it’s hard to gain customers, but if your customers are loyal to your brand, they’ll be willing to pay to access your app. A benefit of having a paid app is that you gain revenue with every download.</p>
          <div class="flex justify-center my-4">
            <img :src="require('@/assets/Stat1.png')" :alt="name" class="w-4/5" />
          </div>
        </div>
        <!-- sec2 -->
        <div>
          <h2>2. BY 2022, THE NUMBER OF MOBILE USERS WORLDWIDE IS FORECAST TO GROW TO 7.26 BILLION. (STATISTA)</h2>
          <p>If you’re questioning whether going mobile is the best move for your brand, use this stat as a sign. Going mobile doesn’t mean you need to have an app, it can also mean creating a mobile-friendly site to match your desktop experience. No matter what route is best for your brand, it’s time to make yourself accessible on-the-go.</p>
          <div class="flex justify-center my-4">
            <img :src="require('@/assets/Stat1.png')" :alt="name" class="w-4/5" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "./style.scss";
export default {
  created() {
    document.querySelector("head title").innerText = "Blogs | Gap Dynamics";
  },
  props: ["bgcolor"],
};
</script>
