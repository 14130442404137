<template>
  <div :style="blog_details_bg">
    <div class="blog_detail_page">
      <div class="section1 text-center">
        <h1>Statistics</h1>
        <h2>25+ Mobile Stats To Know In 2020</h2>
      </div>
    </div>
    <!-- <BgImage /> -->
  </div>
</template>
<script>
import "./style.scss";
export default {
  components: {},
  data() {
    return {
      blog_details_bg: {
        backgroundImage: `url(${require("@/assets/imgs/blog-det-bg.png")})`,
        backgrounRepeat: `no-repeat`,
        backgroundSize: `cover`,
      },
    };
  },
  created() {
    document.querySelector("head title").innerText = "Blogs | Gap Dynamics";
  },
};
</script>
