<template>
  <div id="BlogPage" class="page">
    <link
      rel="stylesheet"
      href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
      integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
      crossorigin="anonymous"
    />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 bgcolor="#000000" />
    <Section3 />
    <Section4 bgcolor="#ae0000" />
    <Section3 />
    <Section5 />
    <div class="carousel-sec py-8">
      <a-row class="mx-8 items-center">
        <a-col
          :sm="{ span: 5 }"
          :md="{ span: 5 }"
          :lg="{ span: 2 }"
          :xlg="{ span: 2 }"
        >
          <div class="heading">Recent</div>
        </a-col>
        <a-col
          :sm="{ span: 16 }"
          :md="{ span: 16 }"
          :lg="{ span: 20 }"
          :xlg="{ span: 2 }"
        >
          <a-divider />
        </a-col>
        <!-- <a-col :span="3"> -->
        <!-- <div class="arrows flex">
          <a class="posts__arrow mr-2" href="#">
            <i class="fas fa-caret-left" style="color: #000;font-size: 47px;"></i>
          </a>
          <a class="posts__arrow ml-2" href="#">
            <i class="fas fa-caret-right" style="color: #000;font-size: 47px;"></i>
          </a>
        </div>-->
        <!-- </a-col> -->
        <Carousel />
      </a-row>
    </div>
  </div>
</template>

<script>
import Section1 from "@/components/blogDetails/section1";
import Carousel from "@/components/blogDetails/carousel";
import Section2 from "@/components/blogDetails/section2";
import Section3 from "@/components/blogDetails/section3";
import Section4 from "@/components/blogDetails/section4";
import Section5 from "@/components/blogDetails/section5";
import "./style.scss";
export default {
  components: {
    Carousel,
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
  },
  created() {
    document.querySelector("head title").innerText = "Blog Page | Gap Dynamics";
  },
};
</script>
